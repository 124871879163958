@import '@taxmonitor/front-core/build/static/css/styles.css';

body {
    width: 100% !important;
    overflow-y: visible !important;
}

/*VK UI*/
.vkuiFixedLayout {
    padding-left: 0 !important;
}

.vkuiPanelHeader * {
    box-sizing: content-box !important;
}

.vkuiPanelHeaderContent__before {
    display: flex;
    align-items: center;
}

.panelPadding {
    padding: 12px 12px 20px;
}

.panelPaddingWithFixedLayout {
    padding: 68px 12px 20px;
}

/*Модалальное окно*/
/*TODO: убрать эти стили, когда модалка станет адаптивной*/
.ant-modal {
      min-width: 0 !important;
      width: 100% !important;
}

.ant-modal-body > div {
    width: auto;
}

.ant-tooltip {
    z-index: 20000000;
}

/*Иконка и текст пустой таблицы*/
/*TODO: убрать эти стили, когда иконка станет адаптивной*/
.ant-table-empty .ant-table-cell .anticon {
    width: 40px;
    height: 40px;
}

.ant-table-empty .ant-table-cell .ant-typography {
    font-size: 13px;
}